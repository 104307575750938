<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">

        <div class="card-header text-end">
          <img src="@/assets/bg.png" class="image-bg" alt="image" />
          <div>
            <i @click="edit" class="bi bi-gear-fill icon"></i>
          </div>

          <!-- <div class="row position-absolute bottom-0 end-0 bg-white" style="height: 30px; width: 100%;">

            <div class="col-12 ">
              <span class="text-root mini-h">จัดการโปรไฟล์</span>

            </div>


          </div> -->

          <div style="
        width: 125px;
        height: 60px;
        position: absolute;
        z-index: 1;
        left: 15%;
        bottom: 3%;
        transform: translate(-50%, -50%);
        text-align: center;
        background-color:transparent;
      ">
            <img src="@/assets/logo.png"
              style="width: 65%;  border-radius: 50%;" />
          </div>


          <div class="row text-end mn-profile w-100 m-0">
            <span class="text-root mini-h text-black">จัดการโปรไฟล์</span>
          </div>

        </div>




        <div class="card-body mt-2 white">
          <div class="title text-end">
            <i style="
                font-size: 20px;
                color: steelblue;
                float: left;
                margin-top: -5px;
              " class="bi bi-person-circle"><span
                style="font-size: 14px; margin-left: 5px; color: black">ข้อมูลส่วนตัว</span></i>
            <span @click="edit" class="title-text">แก้ไขโปรไฟล์</span><i class="bi bi-arrow-bar-right"></i>
          </div>
          <div class="body-text mb-47" style="padding: 10px;">
            <!--begin::Row-->
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">ชื่อ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark"><span v-if="Profire.fn_other">{{ Profire.fn_other }}</span>
                  <span v-else>{{ Profire.fn }}</span> {{ Profire.firstname }}
                  {{ Profire.lastname }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">เพศ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{
                  Profire.gender
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">เบอร์โทรศัพท์</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{ Profire.tel }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <br />
            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-12 fw-bold text-muted">ที่อยู่อาศัย</label>
              <!--end::Label-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">บ้านเลขที่</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{
                  Profire.numberhome
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">หมู่</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span v-if="Profire.moo" class="fw-bolder fs-6 text-dark">{{
                  Profire.moo
                }}</span>
                <span v-else class="fw-bolder fs-6 text-dark">-</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">ซอย</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span v-if="Profire.alleyway" class="fw-bolder fs-6 text-dark">{{ Profire.alleyway }}</span>
                <span v-else class="fw-bolder fs-6 text-dark">-</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">ถนน</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span v-if="Profire.road" class="fw-bolder fs-6 text-dark">{{
                  Profire.road
                }}</span>
                <span v-else class="fw-bolder fs-6 text-dark">-</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">ตำบล</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{
                  Profire.district
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">อำเภอ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{
                  Profire.amphoe
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">จังหวัด</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{
                  Profire.province
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">รหัสไปรษณีย์</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">{{
                  Profire.zipcode
                }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
        </div>

        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button @click="close" class="button">ปิดหน้านี้</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import liff from "@line/liff";
export default defineComponent({
  name: "Profile",
  setup() {
    const store = inject("store");
    const router = inject("router");

    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;
    const AppName = process.env.VUE_APP_NAME;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };

    return {
      edit,
      close,
      Profire,
      ProfireLine,
      AppName
    };
  },
});
</script>

<style scoped>
.card-header {
  background-color: var(--color);
  position: relative;
  height: auto;
  min-height: 170px;
  overflow: hidden;
  border-bottom: none !important;
  padding: 0 !important;
}

.image-bg {
  filter: opacity(50%);
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.mini-h {
  font-size: 20px;
  margin-left: 5px;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: white;
}

.header-logo-text {
  color: white;
  position: absolute;
  top: 42px;
  width: 100%;
}

.img-profile {
  width: 80px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  background-color: white;
}

.img-logo {
  width: 80px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  background-color: white;
}


.title-home {
  font-size: 20px;
  font-weight: bold;
  background-color: #0196c280;
  color: whitesmoke;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mn-profile {
  position: absolute;
  bottom: 0;
  height: auto;
  border-bottom: 2px solid var(--color);
  padding: 10px;
  background-color: white;
  border-radius: 25px 25px 0px 0px;
}
</style>
